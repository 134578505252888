.wallBack {
  height: calc(var(--card-separator-y) + 1rem);
  @apply absolute inset-0 w-full;
}

.carouselDefault {
  grid-column: 1 / -1;
  grid-row: 3;
  @apply pt-4;
}

@screen sm {
  .carouselDefault {
    @apply pl-4 pr-4;
  }
}

@screen md {
  .carouselDefault {
    @apply mt-12 ml-0;
  }
}

.carouselInline {
  grid-column: 1 / -1;
  grid-row: 2 / -1;
  @apply pt-4;
}

@screen sm {
  .carouselInline {
    @apply pl-4 pr-4;
  }
}

@screen md {
  .carouselInline {
    grid-column: 2;
    grid-row: 1 / 3;
  }
}

.carouselBgFill {
}

@screen sm {
  .carouselBgFill {
    @apply px-0;
  }
}

.arrowBoxPrev {
  display: none;
}

@screen carouselMd {
  .arrowBoxPrev {
    top: 0;
    left: -78px;
    @apply absolute flex flex-col justify-center;
  }
}

.arrowStart {
  display: none !important;
}

.arrowBoxNext {
  display: none;
}

@screen carouselMd {
  .arrowBoxNext {
    top: 0;
    right: -78px;
    @apply absolute flex flex-col justify-center;
  }
}

.arrowMobileNext {
  opacity: 0;
  top: calc(var(--card-separator-y) / 2 - 10px);
  @apply absolute flex flex-col justify-center z-1;
}
.arrowMobileNextFilled {
}

@screen sm {
  .arrowMobileNext {
    right: 6px !important;
    left: auto !important;
  }
  .arrowMobileNextFilled {
    right: 0 !important;
  }
}

@screen carouselMd {
  .arrowMobileNext {
    display: none !important;
  }
}

.arrowMobilePrev {
  display: none;
}
.arrowMobilePrevFilled {
}

@screen sm {
  .arrowMobilePrev {
    top: calc(var(--card-separator-y) / 2 - 10px);
    left: 6px !important;
    @apply absolute flex flex-col justify-center;
  }
  .arrowMobilePrevFilled {
    left: 0 !important;
  }
}

@screen carouselMd {
  .arrowMobilePrev {
    display: none !important;
  }
}

.arrow {
  font-size: min(46px, 10vw);
  line-height: min(46px, 10vw);
  padding-bottom: 4px;
  background-color: var(--carousel-bg-color);
  color: var(--bg-color-contrast);
  z-index: 2;
  margin-left: 2px;
  @apply outline-none cursor-pointer;
}

@screen carouselMd {
  .arrow {
    background-color: transparent;
    color: var(--text-color);
  }
}

.arrowEnd {
  display: none !important;
}

.swiperCarouselCenter {
}

.swiperCarouselCenter :global(.swiper-wrapper) {
  justify-content: center;
}

.swiperCarousel {
}

/* .swiperCarousel :global(.swiper-container) {
  overflow-y: visible;
  overflow-x: clip;
} */

.swiperCarousel :global(.swiper-container) {
  height: auto !important;
}

.swiperCarousel :global(.swiper-wrapper) {
  /* height: var(--slide-height) !important; */
  height: auto !important;
}

.swiperCarousel :global(.swiper-slide) {
  width: 50%;
}

@media (min-width: 640px) {
  .swiperCarousel :global(.swiper-slide) {
    width: "33.33333333%";
  }
}

@media (min-width: 1280px) {
  .swiperCarousel :global(.swiper-slide) {
    width: 20%;
  }
}

.swiperCarousel :global(.swiper-button-prev) {
  left: -10px;
}

.swiperCarousel :global(.swiper-button-next) {
  right: -22px;
}

.carouselItem {
  height: 100%;
  padding: 3px 3px 16px 3px;
  @apply relative bg-white;
}

@screen sm {
  .carouselItem {
    padding: 3px;
  }
}

.productGridItem {
  @apply bg-white m-0 pb-2 !important;
}

.slideBg {
  z-index: -1;
  @apply absolute inset-0 w-full bg-white;
}
