.root {
  margin: 42px 0 42px;
  @apply sm:my-8;
}
.titleDesktop {
  font-family: Quattrocento;
  font-weight: 400;
}
.title {
  margin: 0;
  font-size: 30px;
  line-height: 30px;
  font-weight: 400;
  text-align: center;
  font-family: Quattrocento;
}
.subtitle {
  margin-top: 8px;
  font-weight: 300;
  color: rgb(60, 60, 59);
  font-size: 24px;
  text-align: center;
}
.slideCta {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 56%;
}
.slideCtaButton {
  font-weight: 500;
  text-decoration: underline;
}
.swiperCarousel {
}

.swiperCarousel :global(.swiper-slide) {
  width: 50%;
}

@media (min-width: 640px) {
  .swiperCarousel :global(.swiper-slide) {
    width: "33.33333333%";
  }
}

@media (min-width: 1280px) {
  .swiperCarousel :global(.swiper-slide) {
    width: 20%;
  }
}

.carousel {
  @apply mt-8;
}

.carousel :global(.swiper-pagination-bullet-active) {
  margin: 0 7px;
  opacity: 1;
  border: 1px solid #333;
  border-radius: 100%;
  background-color: transparent;
}

.carousel :global(.swiper-pagination-bullet) {
  margin: 0 8px;
  width: 10px;
  height: 10px;
}

.carousel :global(.swiper-button-prev) {
  left: -10px;
}

.carousel :global(.swiper-button-next) {
  right: -22px;
}

.carouselItem {
  padding-left: 15px;
  height: 100%;
}
.cta {
  text-align: center;
  margin-top: 32px;
}
.ctaLabel {
  display: flex;
  align-items: center;
}
.deskLink {
  display: flex;
  color: theme("colors.primary");
}
.deskLinkArrow {
  height: 24px;
  font-size: 22px;
  @apply ml-1;
}
.carouselRoot {
  margin-top: 0 !important;
  @apply mb-12;
}
