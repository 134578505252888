.root {
  background-color: var(--bg-color);
}

@screen sm {
  .root {
    padding-top: 24px;
  }
}

@screen md {
  .root {
    padding: 42px 0 0 0;
    margin-bottom: 42px;
  }
}

.rootDefault {
  @apply pb-5 md:pb-10;
}

.rootInline {
}

.container {
  display: grid;
  grid-template-columns: 55% 45%;
  grid-template-rows: auto auto auto;
}

@screen sm {
  .container {
    grid-template-columns: 34.5% 65.5%;
  }
}

@screen md {
  .container {
    grid-template-columns: 21% 79%;
  }
}

.textColDefault {
  grid-row: 1;
  grid-column: 1 / -1;
}

@screen sm {
  .textColDefault {
    grid-column: 1;
  }
}

.textColInline {
  grid-row: 1;
  grid-column: 1 / -1;
}

.banner {
  grid-row: 1;
  grid-column: 2;
}

.bannerMobile {
  grid-row: 2;
  grid-column: 1 / -1;
  @apply mt-4 sm:hidden;
}

.bannerMobileFilled {
  @apply ml-4;
}

.wallBack {
  height: calc(var(--card-separator-y) + 1rem);
  @apply absolute inset-0 w-full;
}

.productGridItem {
  @apply bg-white m-0 pb-2 !important;
}
