.root {
  width: var(--size);
  height: var(--size);
  background-color: var(--color);
}

@screen sm {
  .root {
    width: var(--size-sm);
    height: var(--size-sm);
  }
}
