.root :global(.tw-typography) {
  font-weight: 400;
  font-family: Quattrocento;
}
.root :global(.tw-typography:first-child) {
  font-weight: 600;
  font-family: CustomManrope;
}
.revert :global(.tw-typography) {
  font-weight: 600;
  font-family: CustomManrope;
}
.revert :global(.tw-typography:first-child) {
  font-weight: 400;
  font-family: Quattrocento;
}
